import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

import SectionStyled from "./SectionStyled";

import { Tariff } from "../ui/Tariff";

import tariff1 from "../../assets/image/tariffs/1.png";
import tariff2 from "../../assets/image/tariffs/2.png";
import tariff3 from "../../assets/image/tariffs/3.png";

export const TariffWrapper = () => (
  <SectionStyled
    tag={"section"}
    bg={"#ffffff"}
    mwidth={"1160px"}
    sxsect={{
      minHeight: "544px",
    }}
    sxcont={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "70px 10px",
    }}
  >
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h4"
        fontWeight={"500"}
        sx={{
          fontSize: {
            xxs: "24px",
            md: "36px",
          },
        }}
      >
        Выберите удобный тариф
      </Typography>
    </Box>
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        marginTop: "40px",
      }}
    >
      <Tariff icon={tariff1} caption={"Тариф на время"} text={"безлимит на 1 месяц, 3, 6, 12"} />
      <Tariff
        icon={tariff2}
        caption={"Тариф на объем"}
        text={"200 шаблонов, 1000 шаблонов, 5000 шаблонов, 10 000 шаблонов"}
      />
      <Tariff icon={tariff3} caption={"Кастом"} text={"Текст-заполнитель —  это текст, который имеет "} />
    </Box>

    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        width: "100%",
        flexDirection: "column",
        paddingBottom: "10px",
      }}
    >
      <Typography
        variant="h4"
        fontWeight={"400"}
        fontSize={"18px"}
        sx={{
          marginTop: "30px",
          fontWeight: "400",
        }}
      >
        Или попробуйте наш
        <Box
          component="span"
          sx={{
            color: "#7E2AEA",
            cursor: "pointer",
          }}
        >
          бесплатный план
        </Box>
      </Typography>
    </Box>
  </SectionStyled>
);
