import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import SectionStyled from "../lib/SectionStyled";

import arrow from "../../assets/image/arrow.png";
import amoLabel from "../../assets/image/amoLabel.png";

const BoxWidget = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    maxWidth: "none",
    alignItems: "center",
  },
}));

const BoxArrow = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

const BoxRight = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    marginTop: "25px",
  },
}));

const BoxButton = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    padding: "0",
  },
}));

export default function Component() {
  return (
    <SectionStyled
      tag={"section"}
      bg={"#7E2AEA"}
      mwidth={"1160px"}
      sxsect={{
        minHeight: "350px",
      }}
      sxcont={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "70px 10px",
      }}
    >
      <BoxWidget
        sx={{
          maxWidth: "537px",
          display: "flex",
          flexDirection: "column",
          paddingTop: "15px",
        }}
      >
        <Typography
          color={"#FFFFFF"}
          sx={{
            fontSize: "calc(29px + 3 * (100vw / 1160))",
            lineHeight: {
              xxs: "28px",
              md: "42px",
            },
            fontWeight: "500",
          }}
        >
          Виджет устанавливается всего за 2 минуты
        </Typography>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "400",
              color: "#ffffff",
              marginTop: "30px",
            }}
          >
            для начала работы с GetDoc не нужен программист
          </Typography>
        </Box>
      </BoxWidget>
      <BoxRight>
        <BoxArrow>
          <img src={arrow} />
        </BoxArrow>
        <BoxButton
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "62px 107px 0 140px",
          }}
        >
          <Button
            variant="contained"
            component="button"
            sx={{
              textTransform: "none",
              borderRadius: "8px",
              fontSize: "18px",
              fontWeight: "normal",
              backgroundColor: "#ffffff",
              color: "#000000",
            }}
          >
            <img src={amoLabel} style={{ marginRight: "15px" }} />
            Установить самостоятельно
          </Button>
        </BoxButton>
      </BoxRight>
    </SectionStyled>
  );
}
