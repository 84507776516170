export const buttonMenu = [
  {
    menuTitle: "Menu1",
    pageUrl: "/",
  },
  {
    menuTitle: "Menu2",
    pageUrl: "/",
  },
  {
    menuTitle: "Menu3",
    pageUrl: "/",
  },
  {
    menuTitle: "Menu4",
    pageUrl: "/",
  },
  {
    menuTitle: "Menu5",
    pageUrl: "/",
  },
  {
    menuTitle: "Menu6",
    pageUrl: "/",
  },
  {
    menuTitle: "Menu7",
    pageUrl: "/",
  },
];
