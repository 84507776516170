import React from "react";

import { Tabs, Tab, Box, useMediaQuery, useTheme, Button, SwipeableDrawer, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Oferta from "./content/oferta.js";
// import PrivacyPolicy from "./content/PrivacyPolicy";

const themes = ["Оферта"];
const windows = [<Oferta />];

const Table = ({ page, pageHC }) => (
  <>
    <IconButton component="button" sx={{ color: "white" }} onClick={() => window.history.back()}>
      <ArrowBackIcon />
    </IconButton>
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={page}
      onChange={(e, i) => pageHC(i)}
      aria-label="Vertical tabs example"
      sx={{
        backgroundColor: "#434657",
        borderRight: 1,
        borderColor: "#434657",
        width: "100%",
        "& .MuiTab-root.Mui-selected": {
          color: "#ffffff",
          backgroundColor: "#252734",
          textTransform: "capitalize",
        },
      }}
      TabIndicatorProps={{
        style: {
          background: "#252734",
        },
      }}
    >
      {themes.map((e, index) => (
        <Tab
          key={index}
          sx={{
            color: "#ffffff",
            fontSize: "16px",
            width: "100%",
            textTransform: "capitalize",
          }}
          label={e}
        />
      ))}
    </Tabs>
  </>
);
export default function Component() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [page, setPage] = React.useState(0);
  const pageHC = (i) => {
    setPage(i);
  };
  const [drawer, setDrawer] = React.useState(false);
  React.useEffect(() => {
    document.getElementsByTagName("html")[0].scrollTop = 0;
  }, []);

  return (
    <>
      {isMobile && (
        <Button component="button" sx={{ position: "fixed" }} variant="gray" onClick={() => setDrawer(true)}>
          меню
        </Button>
      )}
      <Box
        sx={{
          height: "100vh",
          backgroundColor: "#ffffff",
          display: "flex",
          width: "100%",
        }}
      >
        {!isMobile && (
          <Box width="20vw" backgroundColor="#434657">
            <Table page={page} pageHC={pageHC} />
          </Box>
        )}
        <Box
          sx={{
            overflow: "auto",
            width: "100%",
            maxHeight: "100vh",
            paddingTop: "20px",
          }}
        >
          {windows[page]}
        </Box>
      </Box>
      <SwipeableDrawer
        anchor="left"
        open={drawer}
        onClose={() => setDrawer(false)}
        onOpen={() => setDrawer(true)}
        sx={{
          "& .MuiPaper-root": {
            width: "50vw",
            backgroundColor: "#434657",
          },
        }}
      >
        <Table page={page} pageHC={pageHC} />
      </SwipeableDrawer>
    </>
  );
}
