import { useState } from "react";
import { Drawer, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import LoginButton from "components/ui/LoginButton";
import { Navigate } from "components/ui/Navigate";

export const Hamburger = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <IconButton component="button">
        <MenuIcon onClick={() => setOpen(true)} />
      </IconButton>
      <Drawer open={open} onClose={() => setOpen(false)}>
        <Navigate />
        <LoginButton />
      </Drawer>
    </>
  );
};
