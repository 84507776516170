import { FC, ReactNode } from "react";

import { Box, styled } from "@mui/system";

import Desktop from "../../assets/image/Desktop.png";

const BoxText = styled("div")(({ theme }: any) => ({
  [theme.breakpoints.down(1100)]: {
    width: "100%",
  },
}));

const BoxImage = styled("div")(({ theme }: any) => ({
  [theme.breakpoints.down(1100)]: {
    width: "100%",
    padding: "0",
  },
}));

interface BoxFichProps {
  children: ReactNode;
}

export const BoxFich: FC<BoxFichProps> = ({ children }) => (
  <Box
    sx={{
      minHeight: "300px",
      backgroundColor: "#252734",
      borderRadius: "12px",
      overflow: "hidden",
      display: "flex",
      flexWrap: "wrap",
      marginBottom: "75px",
      padding: "0 15px",
    }}
  >
    <BoxText
      sx={{
        width: "50%",
        padding: "20px",
      }}
    >
      {children}
    </BoxText>
    <BoxImage
      sx={{
        padding: "39px 39px 0 0",
        overflow: "hidden",
      }}
    >
      <img src={Desktop} />
    </BoxImage>
  </Box>
);
