import { PaletteColor, PaletteColorOptions, Theme, createTheme } from "@mui/material";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    template: true;
    gray: true;
  }
}

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xxs: true;
    xs: true;
    sm: true;
    md: true;
  }

  interface Palette {
    lightPurple: PaletteColor;
    darkPurple: PaletteColor;
    brightPurple: PaletteColor;
    fadePurple: PaletteColor;
    grey1: PaletteColor;
    grey2: PaletteColor;
    grey3: PaletteColor;
    orange: PaletteColor;
    navbarbg: PaletteColor;
  }

  interface PaletteOptions {
    lightPurple?: PaletteColorOptions;
    darkPurple?: PaletteColorOptions;
    brightPurple?: PaletteColorOptions;
    fadePurple?: PaletteColorOptions;
    grey1?: PaletteColorOptions;
    grey2?: PaletteColorOptions;
    grey3?: PaletteColorOptions;
    orange?: PaletteColorOptions;
    navbarbg?: PaletteColorOptions;
  }
}

export const theme: Theme = createTheme({
  breakpoints: {
    values: {
      xxs: 300,
      xs: 460,
      sm: 600,
      md: 680,
      lg: 900,
      xl: 1160,
    },
  },
  typography: {
    fontFamily: "Rubik",
  },
  palette: {
    primary: {
      main: "#7E2AEA",
    },
    secondary: {
      main: "#252734",
    },
    text: {
      primary: "#000000",
      secondary: "#7E2AEA",
    },
    background: {
      default: "#F2F3F7",
    },
    lightPurple: {
      main: "#333647",
    },
    darkPurple: {
      main: "#252734",
    },
    brightPurple: {
      main: "#7E2AEA",
    },
    fadePurple: {
      main: "#C19AF5",
    },
    grey1: {
      main: "#434657",
    },
    grey2: {
      main: "#9A9AAF",
    },
    grey3: {
      main: "#4D4D4D",
    },
    orange: {
      main: "#FB5607",
    },
    navbarbg: {
      main: "#FFFFFF",
    },
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: "xl",
        style: {
          boxSizing: "border-box",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: {
            variant: "template",
          },
          style: {
            width: "275px",
            height: "66px",
            backgroundColor: "#ffffff",
            borderRadius: "12px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            paddingRight: "77px",
            textTransform: "none",
            color: "#4D4D4D",
          },
        },
        {
          props: {
            variant: "gray",
          },
          style: {
            width: "100%",
            height: "30px",
            backgroundColor: "#252734",
            borderRadius: "2px",
            color: "#F2F3F7",
            "&:hover": {
              backgroundColor: "#2c2f3f",
            },
          },
        },
      ],
    },
  },
});

const themeHeader = createTheme(theme, {
  components: {
    Header: {
      styleOverrides: {
        Logo: {
          display: "none",
          width: 100,
          height: 38,
          [theme.breakpoints.down("md")]: {
            display: "block",
          },
        },
        LoginBtn: {
          borderRadius: "8px",
          padding: "10px 18px",
          fontSize: "18px",
          lineHeight: "24px",
          fontWeight: 400,
          textTransform: "none",
          border: "1px solid black",
          color: "black",
        },
        Menu: {
          marginRight: "5px",
          "& a": {
            marginRight: "30px",
            color: "black",
            textDecoration: "none",
            fontWeight: 500,
            "&:hover": {
              color: "#7E2AEA",
            },
          },
          [theme.breakpoints.down("md")]: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "5px",
            "& a": {
              marginRight: "0",
              marginBottom: "5px",
            },
          },
        },
      },
    },
  },
});
