import React from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, CssBaseline, Typography, useMediaQuery } from "@mui/material";
import { ThemeProvider } from "@mui/system";

import { Header } from "../components/ui/Header";
import Footer from "../components/ui/Footer";
import SectionStyled from "../components/lib/SectionStyled";
import { CardItemTour } from "components/lib/CardItemTour";

import { cardsTour } from "__mocks__/cardsTour";

import { theme } from "../theme/theme";

export default function TourPage() {
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <React.Fragment>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Header />
        <SectionStyled
          tag={"footer"}
          bg={"#F2F3F7"}
          mwidth={"1160px"}
          sxsect={{
            justifyContent: "space-around",
          }}
          sxcont={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column",
            minHeight: "300px",
            padding: {
              md: "100px 10px",
              xxs: "10px",
            },
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              background: "#F2F3F7",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                paddingBottom: "96px",
              }}
            >
              <Link to="/">
                <ArrowBackIcon
                  sx={{
                    display: {
                      xxs: "block",
                      md: "none",
                    },
                  }}
                />
              </Link>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: "20px",
                }}
              >
                <Typography
                  variant="h6"
                  fontSize="calc(29px + 23 * (100vw / 1160))"
                  sx={{
                    lineHeight: {
                      xxs: "38px",
                      md: "70px",
                    },
                    marginTop: "20px",
                    marginBottom: "20px",
                    width: "min-content",
                  }}
                >
                  Пошаговое руководство
                </Typography>
                <Typography
                  sx={{
                    fontSize: "24px",
                    maxWidth: "500px",
                    lineHeight: "28px",
                    alignSelf: "flex-end",
                  }}
                  variant="h6"
                >
                  Текст-заполнитель — это текст, который имеет некоторые характеристики реального письменного
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                "&>*:nth-child(2n+1)": {
                  backgroundColor: "#FFF",
                  flexDirection: "row-reverse",
                },
              }}
            >
              {cardsTour.map(({ title, text, image }, index) => (
                <CardItemTour key={index} title={title} text={text} image={image} />
              ))}
            </Box>
          </Box>
        </SectionStyled>
        <Footer />
      </ThemeProvider>
    </React.Fragment>
  );
}
