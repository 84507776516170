import { getDeclension } from "./declension";

export function isDateToday(date: Date): boolean {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return date.getTime() > today.getTime();
}

const avgDaysInMonth = 30.43692;
const avgDaysInYear = 365.242199;

export function formatDateWithDeclention(numberOfDays: number): string {
  if (numberOfDays === 0) return "0 дней";

  const years = Math.floor(numberOfDays / avgDaysInYear);
  const months = Math.floor((numberOfDays % avgDaysInYear) / avgDaysInMonth);
  const days = Math.floor((numberOfDays % avgDaysInYear) % avgDaysInMonth);

  const yearsDisplay = years > 0 ? `${years} ${getDeclension(years, "год")}` : "";
  const monthsDisplay = months > 0 ? `${months} ${getDeclension(months, "месяц")}` : "";
  const daysDisplay = days > 0 ? `${days} ${getDeclension(days, "день")}` : "";

  return `${yearsDisplay} ${monthsDisplay} ${daysDisplay}`;
}
