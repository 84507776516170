import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

import logotip from "../../assets/Icons/logoPenaHab.svg";
import SectionStyled from "components/lib/SectionStyled";

const RootBox = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
  },
}));

const BoxServis = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
  },
}));

const BoxMenu = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}));

export default function component() {
  return (
    <SectionStyled
      tag="footer"
      bg="#252734"
      mwidth="1160px"
      sxsect={{
        justifyContent: "space-around",
      }}
      sxcont={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "300px",
        padding: {
          md: "100px 10px",
          xxs: "50px 10px 10px 10px",
        },
        boxSizing: "border-box",
      }}
    >
      <Box>
        <RootBox
          sx={{
            display: "flex",
            height: "50%",
            justifyContent: {
              xxs: "flex-start",
              md: "space-between",
            },
            alignItems: "center",
            width: "100%",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              width: "124px",
              height: "48px",
              backgroundSize: "contain",
              marginBottom: "20px",
            }}
          >
            <img src={logotip} alt="logotip" />
          </Box>

          <Box
            sx={{
              display: "flex",
              width: "457px",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <BoxMenu
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: {
                  xxs: "flex-start",
                  md: "center",
                },
                button: {
                  paddingLeft: "0",
                  color: "#F2F3F7",
                  textTransform: "none",
                },
              }}
            >
              <Link to="/docs">
                <Button variant="text" component="button">
                  Оферта
                </Button>
              </Link>
              <Button component="button" variant="text">
                Меню 1
              </Button>
              <Button variant="text" component="button">
                Меню 1
              </Button>
            </BoxMenu>
            <BoxMenu
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: {
                  xxs: "flex-start",
                  md: "center",
                },
                button: {
                  paddingLeft: "0",
                  color: "#F2F3F7",
                  textTransform: "none",
                },
              }}
            >
              <Button variant="text" component="button">
                Меню 1
              </Button>
              <Button variant="text" component="button">
                Меню 1
              </Button>
              <Button variant="text" component="button">
                Меню 1
              </Button>
            </BoxMenu>
            <BoxMenu
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: {
                  xxs: "flex-start",
                  md: "center",
                },
                button: {
                  paddingLeft: "0",
                  color: "#F2F3F7",
                  textTransform: "none",
                },
              }}
            >
              <Button variant="text" component="button">
                Меню 1
              </Button>
              <Button variant="text" component="button">
                Меню 1
              </Button>
              <Button variant="text" component="button">
                Меню 1
              </Button>
            </BoxMenu>
          </Box>
          <BoxServis
            sx={{
              minHeight: "105px",
              width: "275px",
            }}
          >
            <Typography
              sx={{
                textAlign: "left",
                fontWeight: "500",
                borderBottom: {
                  xxs: "2px solid white",
                  md: "none",
                },
                paddingBottom: "20px",
              }}
              width={"inherit"}
              color={"#F2F3F7"}
            >
              Сервисы помогают предпринимателям, маркетологам и агентствам сделать интернет-маркетинг прозрачным
            </Typography>
          </BoxServis>
        </RootBox>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "end",
            height: "50%",
          }}
        >
          <Typography
            sx={{
              textAlign: {
                xxs: "left",
                md: "center",
              },
              fontWeight: "500",
            }}
            color={"#F2F3F7"}
          >
            Конструктор маркетинговых решений. © 2022
          </Typography>
        </Box>
      </Box>
    </SectionStyled>
  );
}
