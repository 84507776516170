import { Box, Button } from "@mui/material";
import { buttonMenu } from "__mocks__/buttonMenu";
import { FC } from "react";
import { Link } from "react-router-dom";

const styleLink = {
  marginRight: "30px",
  color: "black",
  textDecoration: "none",
  fontWeight: 500,
  "a:hover": {
    color: "#7E2AEA",
  },
};

export const Navigate: FC = () => (
  <Box sx={{ marginRight: "5px" }} component="nav">
    {buttonMenu.map((e, index) => (
      <Button
        key={index}
        component={() => (
          <Link style={styleLink} to={e.pageUrl}>
            {e.menuTitle}
          </Link>
        )}
      />
    ))}
  </Box>
);
