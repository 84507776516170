import { FC } from "react";

import logoPenaHub from "../../assets/Icons/black_logo_PenaHab.svg";
import { useTheme } from "@mui/system";
import { useMediaQuery } from "@mui/material";

const LogoWrapper: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <img style={{ display: isMobile ? "block" : "none", width: 100, height: 38 }} src={logoPenaHub} alt="PenaLtd" />
  );
};

export default LogoWrapper;
