import React, { FC } from "react";
import Box from "@mui/material/Box";
import { Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import { ProfitCard } from "./ui/ProfitCard";

import SectionStyled from "./lib/SectionStyled";

import install1 from "../assets/image/install/1.png";
import install2 from "../assets/image/install/2.png";
import install3 from "../assets/image/install/3.png";
import install4 from "../assets/image/install/4.png";
import install5 from "../assets/image/install/5.png";
import install6 from "../assets/image/install/6.png";
import amoLabel from "../assets/image/amoLabel.png";

const BoxCard = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
  },
}));

export const InstallSection: FC = () => (
  <SectionStyled
    tag={"section"}
    bg={"#f2f3f7"}
    mwidth={"1160px"}
    sxsect={{ minHeight: "911px" }}
    sxcont={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      height: "100%",
      color: "#000000",
      padding: "55px 14px 63px 10px",
    }}
  >
    <Box>
      <Typography variant={"h6"} fontSize={"calc(29px + 1 * (100vw / 1160))"} sx={{ fontWeight: "500" }}>
        Установить самостоятельно
      </Typography>
      <Typography
        variant={"h6"}
        fontSize={"18px"}
        sx={{
          fontWeight: "400",
          margin: "15px 0 30px 0",
          maxWidth: "450px",
        }}
      >
        Сократите количество рабочих часов менеджеров по подготовке документов в 2 клика
      </Typography>
    </Box>

    <BoxCard
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
    >
      <ProfitCard pic={install1} tit={"Сократит количество ошибок"} subtit={"и уберет лишнюю нагрузку с менеджеров"} />
      <ProfitCard
        pic={install2}
        tit={"Все документы хранятся в CRM"}
        subtit={"компания не зависит от менеджера и его компьютера"}
      />
      <ProfitCard pic={install3} tit={"Удобно использовать"} subtit={"разобраться с интерфейсом можно за 5 минут"} />
      <ProfitCard
        pic={install4}
        tit={"Не нужен программист"}
        subtit={"начать работу с виджетом можно самостоятельно"}
      />
      <ProfitCard pic={install5} tit={"Дисциплинирует менеджеров"} subtit={"заполнять данные по клиенту"} />
      <ProfitCard pic={install6} tit={"Все данные в одной базе"} subtit={"их можно использовать повторно"} />
    </BoxCard>
    <Button
      variant="contained"
      component="button"
      sx={{
        maxWidth: "350px",
        textTransform: "none",
        borderRadius: "8px",
        fontSize: "18px",
        marginTop: "40px",
        fontWeight: "normal",
        backgroundColor: "#7E2AEA",
        color: "#ffffff",
      }}
    >
      <img alt={"AMOCRM"} src={amoLabel} style={{ marginRight: "15px", filter: "invert(100%)" }} />
      Установить самостоятельно
    </Button>
  </SectionStyled>
);
