import iconFader from "../assets/image/offers/Icon-Faders.svg";
import ArrowsOut from "../assets/image/offers/ArrowsOut.svg";
import ClipboardText from "../assets/image/offers/ClipboardText.svg";
import FolderSimpleStar from "../assets/image/offers/FolderSimpleStar.svg";

export const OfferCards = [
  {
    id: 1,
    title: "Последовательная настройка amoCRM под текущие задачи",
    text: "Объясним, расскажем и поможем\n Предлагаем понятное решение с четкими сроками",
    icon: ArrowsOut,
  },
  {
    id: 2,
    title: "Доработка amoCRM с настройкой хайповых инструментов",
    text: "Вы путешествуете, а мы выполняем задачи\n Непрерывная коммуникация с вашей командой",
    icon: FolderSimpleStar,
  },
  {
    id: 3,
    title: "Комплексная настройка amoCRM с реализацией вашего списка задач",
    text: "Сэкономим ваше время предложим лучшее решение\n Точно в срок без лишних вопросов",
    icon: iconFader,
  },
  {
    id: 4,
    title: "Интеграция amoCRM с сервисами по ТЗ заказчика",
    text: "Мы команда для реализации ваших идей\n Развернем инфраструктуру с разработкой и поддержкой",
    icon: ClipboardText,
  },
];
