import { Button, styled } from "@mui/material";
import { FC } from "react";

const Item = styled(Button)({
  textTransform: "none",
  fontSize: "18px",
  width: "180px",
  height: "44px",
  border: "1px solid #7E2AEA",
  borderRadius: "8px",
  fontWeight: 400,
});

interface ButtonMoreProps {
  text: string;
}

const ButtonMore: FC<ButtonMoreProps> = ({ text }) => {
  return <Item>{text}</Item>;
};

export default ButtonMore;
