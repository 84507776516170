import {
  Box,
  Card,
  Container,
  CssBaseline,
  Grid,
  styled,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import Header from "../components/ui/Header";
import Footer from "../components/ui/Footer";
import { theme } from "../theme/theme";
import ButtonMore from "../components/ui/ButtonMore";

import { OfferCards } from "../__mocks__/оfferCards";

import logoPenaHub from "../assets/Icons/black_logo_PenaHab.svg";
import logoAmoCrm from "../assets/image/logoAmoCrm.png";

const CardInfo = styled(Card)(() => ({
  background: "#FFFFFF",
  boxShadow:
    "0px 100px 309px rgba(210, 208, 225, 0.24), 0px 41.7776px 129.093px rgba(210, 208, 225, 0.172525), 0px 22.3363px 69.0192px rgba(210, 208, 225, 0.143066), 0px 12.5216px 38.6916px rgba(210, 208, 225, 0.12), 0px 6.6501px 20.5488px rgba(210, 208, 225, 0.0969343), 0px 2.76726px 8.55082px rgba(210, 208, 225, 0.0674749)",
  borderRadius: "12px",
  padding: "20px",
  maxWidth: "560px",
  display: "flex",
  [theme.breakpoints.up("lg")]: {
    height: "300px",
  },
  justifyContent: "space-between",
  flexDirection: "column",
  gap: "10px",
}));

export default function OfferPage() {
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <React.Fragment>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Header />
        <Box
          sx={{
            background: "#F2F3F7",
            paddingTop: "73px",
            paddingBottom: "160px",
          }}
        >
          <Container fixed>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "80px",
                justifyContent: "flex-start",
              }}
            >
              {isMobile ? (
                <></>
              ) : (
                <>
                  <img style={{ maxWidth: "180px", maxHeight: "70px" }} src={logoPenaHub} alt="PenaLtd" />
                </>
              )}
              <Typography
                variant="h6"
                fontSize="calc(33px + 23 * (100vw / 1160))"
                sx={{
                  lineHeight: "70px",
                  marginTop: "20px",
                  maxWidth: "800px",
                }}
              >
                Услуги по внедрению и настройке <img src={logoAmoCrm} alt="AmoCRM" />
              </Typography>
              <Grid container columns={{ xs: 2, lg: 6, xl: 12 }} columnGap="justify-content" rowGap="20px" spacing={2}>
                {OfferCards.map((card, index) => (
                  <Grid item xs={6} key={index}>
                    <CardInfo>
                      <Box
                        sx={{
                          maxWidth: "36px",
                          maxHeight: "36px",
                          borderRadius: "6px",
                          background: "#7E2AEA",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "10.5px 9px",
                        }}
                      >
                        <img src={card.icon} alt="icon" />
                      </Box>
                      <Typography
                        sx={{
                          marginTop: "4px",
                          fontSize: "24px",
                          fontWeight: "500",
                          lineHeight: "28px",
                        }}
                        variant="h4"
                        color="#000000"
                      >
                        {card.title}
                      </Typography>

                      <Box
                        sx={{
                          marginBottom: "24px",
                        }}
                      >
                        {card.text.split("\n").map((i, key) => {
                          return (
                            <Typography
                              variant="h6"
                              color="#4D4D4D"
                              sx={{
                                fontSize: "18px",
                                lineHeight: "21px",
                                fontWeight: 400,
                              }}
                              key={key}
                            >
                              {i}
                            </Typography>
                          );
                        })}
                      </Box>
                      <ButtonMore text="Подробнее" />
                    </CardInfo>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Container>
        </Box>
        <Footer />
      </ThemeProvider>
    </React.Fragment>
  );
}
