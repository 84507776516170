export const cardsTour = [
  {
    title: "Текст-заполнитель — это текст, который имеет некоторые характеристики",
    text: "Текст-заполнитель — это текст, который имеет некоторые характеристики реального письменного. Текст-заполнитель — это текст, который имеет некоторые характеристики реального письменного.Текст-заполнитель — это текст, который имеет некоторые характеристики реального письменного.Текст-заполнитель — это текст, который имеет некоторые характеристики реального письменного",
    image: "../assets/image/Rectangle.png",
  },
  {
    title: "Текст-заполнитель — это текст, который имеет некоторые характеристики",
    text: "Текст-заполнитель — это текст, который имеет некоторые характеристики реального письменного. Текст-заполнитель — это текст, который имеет некоторые характеристики реального письменного.Текст-заполнитель — это текст, который имеет некоторые характеристики реального письменного.Текст-заполнитель — это текст, который имеет некоторые характеристики реального письменного",
    image: "../assets/image/Rectangle.png",
  },
  {
    title: "Текст-заполнитель — это текст, который имеет некоторые характеристики",
    text: "Текст-заполнитель — это текст, который имеет некоторые характеристики реального письменного. Текст-заполнитель — это текст, который имеет некоторые характеристики реального письменного.Текст-заполнитель — это текст, который имеет некоторые характеристики реального письменного.Текст-заполнитель — это текст, который имеет некоторые характеристики реального письменного",
    image: "../assets/image/Rectangle.png",
  },
  {
    title: "Текст-заполнитель — это текст, который имеет некоторые характеристики",
    text: "Текст-заполнитель — это текст, который имеет некоторые характеристики реального письменного. Текст-заполнитель — это текст, который имеет некоторые характеристики реального письменного.Текст-заполнитель — это текст, который имеет некоторые характеристики реального письменного.Текст-заполнитель — это текст, который имеет некоторые характеристики реального письменного",
    image: "../assets/image/Rectangle.png",
  },
];
