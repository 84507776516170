import React from "react";

import { Header } from "./components/ui/Header";
import { HomeSection } from "./components/HomeSection";
import { EarnMoreSection } from "./components/EarnMoreSection";
import { InstallSection } from "./components/InstallSection";
import { TariffWrapper } from "./components/lib/TariffWrapper";
import { ManagersSection } from "./components/ManagersSection";
import { EffectSection } from "./components/EffectSection";
import Widget from "./components/widget/widget";
import Footer from "./components/ui/Footer";
import FloatingSupportChat from "./components/floatingSupportChat/FloatingSupportChat";

export default function Component() {
  return (
    <>
      <Header />
      <HomeSection />
      <EarnMoreSection />
      <InstallSection />
      <TariffWrapper />
      <ManagersSection />
      <EffectSection />
      <Widget />
      <Footer />
      <FloatingSupportChat />
    </>
  );
}
