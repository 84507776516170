import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";

import App from "./App";
import Docs from "./docs/docs.js";
import OfferPage from "./pages/OfferPage";
import TourPage from "./pages/TourPage";
import Video from "./pages/VideoPage";

import { theme } from "./theme/theme";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/docs" element={<Docs />} />
            <Route path="/offers" element={<OfferPage />} />
            <Route path="/tour" element={<TourPage />} />
            <Route path="/video" element={<Video />} />
            <Route path="/*" element={<App />} />
          </Routes>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>
);
