import { FC } from "react";
import { Box, SxProps, Theme } from "@mui/system";

interface SquareProps {
  pic?: string;
  sx?: SxProps<Theme>;
  num?: number;
}

export const Square: FC<SquareProps> = ({ pic, sx, num }) => {
  return (
    <Box
      sx={{
        backgroundImage: `url( ${pic} )`,
        ...sx,
      }}
    >
      {num}
    </Box>
  );
};
