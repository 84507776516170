import React, { FC } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import SectionStyled from "./lib/SectionStyled";

import logotip from "../assets/Icons/black_logo_PenaHab.svg";
import abstraction from "../assets/image/abstraction.png";
import logoAmoCrm from "../assets/image/logoAmoCrm.png";

const BoxHead = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "100%",
    alignItems: "center",
  },
}));

const Logo = styled("img")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const AmoLogo = styled("img")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "161px",
  },
}));

const BoxImage = styled(Box)(({ theme }) => ({
  width: "60%",
  height: "100%",
  textAlign: "right",
  [theme.breakpoints.down("md")]: {
    width: "294px",
    height: "auto",
  },
}));

export const HomeSection: FC = () => (
  <SectionStyled
    tag={"section"}
    bg={"#f2f3f7"}
    mwidth={"1160px"}
    sxsect={{
      minHeight: "628px",
    }}
    sxcont={{
      display: "flex",
      flexDirection: {
        xxs: "column-reverse",
        md: "row",
      },
      justifyContent: "center",
      alignItems: {
        xxs: "center",
        md: "",
      },
      height: "100%",
      padding: "30px 18px",
      paddingBottom: "70px",
    }}
  >
    <BoxHead
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "50%",
        height: "100%",
        justifyContent: "space-around",
        padding: {
          xxs: "0",
          md: "45px 0 75px 0",
        },
      }}
    >
      <Box
        sx={{
          width: "180px",
          height: {
            xxs: "auto",
            md: "69px",
          },
          backgroundSize: "contain",
        }}
      >
        <Logo src={logotip} alt="logo" />
      </Box>
      <Typography
        variant="h6"
        fontSize="calc(29px + 23 * (100vw / 1160))"
        sx={{
          lineHeight: {
            xxs: "40px",
            md: "70px",
          },
          marginTop: {
            xxs: "0",
            md: "20px",
          },
        }}
      >
        Шаблонизатор для <AmoLogo src={logoAmoCrm} alt="AmoCRM" />
      </Typography>
      <Typography
        variant="h5"
        sx={{
          fontSize: "18px",
          lineHeight: "21.33px",
          maxWidth: "580px",
          margin: {
            xxs: "30px 0",
            md: "10px 0",
          },
        }}
      >
        Виджет для генерации документов в 2 клика уберет рутину с договорами и актами за 2 минуты на основе данных из
        amoCRM и шаблонов документов компании в формате docx и pdf
      </Typography>
      <Button
        variant="contained"
        component="button"
        sx={{
          maxWidth: "355px",
          textTransform: "none",
          borderRadius: "8px",
          fontSize: "18px",
          fontWeight: "normal",
        }}
      >
        Загрузить инструкцию по установке
      </Button>
    </BoxHead>
    <BoxImage>
      <img width={"100%"} src={abstraction} alt="" />
    </BoxImage>
  </SectionStyled>
);
