import { styled } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";

import SectionStyled from "../lib/SectionStyled";
import LogoWrapper from "./LogoWrapper";
import { Navigate } from "./Navigate";
import LoginButton from "./LoginButton";
import { Hamburger } from "components/lib/Hamburger";

export const Header = () => {
  const theme = useTheme();

  return (
    <SectionStyled
      tag={"header"}
      mwidth={"1160px"}
      bg={"#f2f3f7"}
      sxsect={{
        justifyContent: "space-around",
        borderBottom: "1px solid rgba(0,0,0,.1)",
      }}
      sxcont={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "18px 5px",
      }}
    >
      <LogoWrapper />
      {useMediaQuery(theme.breakpoints.up("md")) ? (
        <>
          <Navigate />
          <LoginButton />
        </>
      ) : (
        <Hamburger />
      )}
    </SectionStyled>
  );
};

export default styled(Header, {
  name: "Header",
  slot: "Root",
})(({ theme }: any) => theme.components.Header.styleOverrides.root);
