import React, { FC } from "react";
import Box from "@mui/material/Box";
import { Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import SectionStyled from "./lib/SectionStyled";

import getMore1 from "../assets/image/getMore/1.png";
import getMore2 from "../assets/image/getMore/2.png";
import getMore3 from "../assets/image/getMore/3.png";
import getMore4 from "../assets/image/getMore/4.png";
import amoLabel from "../assets/image/amoLabel.png";
import { Benefit } from "./lib/Benefit";

const BoxAdvantages = styled("div")(({ theme }) => ({
  [theme.breakpoints.down(490)]: {
    justifyContent: "center",
  },
}));

export const EarnMoreSection: FC = () => (
  <SectionStyled
    tag={"section"}
    bg={"#7e2aea"}
    mwidth={"1160px"}
    sxsect={{ minHeight: "607px" }}
    sxcont={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      color: "#ffffff",
      padding: "50px 10px",
    }}
  >
    <Box>
      <Typography
        variant={"h6"}
        fontSize={"36px"}
        sx={{
          fontSize: {
            xxs: "24px",
            md: "36px",
          },
          fontWeight: "500",
        }}
      >
        Зарабатывайте больше на 30%
      </Typography>
      <Typography
        variant={"h6"}
        fontSize={"18px"}
        sx={{
          fontWeight: "400",
          margin: "15px 0 70px 0",
        }}
      >
        благодаря оптимизации работы с документами
      </Typography>
    </Box>
    <BoxAdvantages
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
    >
      <Benefit pic={getMore1} text={"Сократите время на заполнение договоров"} />
      <Benefit pic={getMore2} text={"Исключите ошибки в документах"} />
      <Benefit pic={getMore3} text={"Увеличьте эффективность  отдела продаж"} />
      <Benefit pic={getMore4} text={"Автоматизируйте документооборот"} />
    </BoxAdvantages>

    <Button
      variant="contained"
      component="button"
      sx={{
        maxWidth: "350px",
        textTransform: "none",
        borderRadius: "8px",
        fontSize: "18px",
        marginTop: "40px",
        fontWeight: "normal",
        backgroundColor: "#ffffff",
        color: "#000000",
        marginBottom: "20px",
      }}
    >
      <img alt={"AMOCRM"} src={amoLabel} style={{ marginRight: "15px" }} />
      Установить самостоятельно
    </Button>
  </SectionStyled>
);
