import React, { FC } from "react";
import Box from "@mui/material/Box";
import { Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import SectionStyled from "./lib/SectionStyled";
import { EffectCard } from "./ui/EffectCard";

const BoxToDo = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    justifyContent: "space-between",
  },
}));

export const EffectSection: FC = () => (
  <SectionStyled
    tag={"section"}
    bg={"#f2f3f7"}
    mwidth={"1160px"}
    sxsect={{ minHeight: "661px" }}
    sxcont={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      height: "100%",
      color: "##f2f3f7",
      padding: "70px 10px",
    }}
  >
    <Box>
      <Typography
        variant={"h6"}
        sx={{
          fontWeight: "500",
          fontSize: {
            xxs: "24px",
            md: "36px",
          },
          maxWidth: "750px",
        }}
      >
        Эффективность отдела продаж возрастет на 30%
      </Typography>
      <Typography
        variant={"h6"}
        fontSize={"18px"}
        sx={{
          fontWeight: "400",
          margin: "15px 0 0 0",
          maxWidth: "450px",
        }}
      >
        а менеджеры будут довольны уменьшением количества рутины
      </Typography>
    </Box>
    <BoxToDo
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
    >
      <EffectCard num={1} tit={"Заходим в сделку, открываем виджет getdoc"} subtit={""} />
      <EffectCard num={2} tit={"Выбираем типовой шаблон"} subtit={"(договор, счет, акт, счет-фактура и другие)"} />
      <EffectCard num={3} tit={"Выбираем нужный формат"} subtit={"docx, pdf"} />
      <EffectCard num={4} tit={"Готовый шаблон скачан на компьютер"} subtit={""} />
    </BoxToDo>

    <Button
      variant="contained"
      component="button"
      sx={{
        maxWidth: "353px",
        minHeight: "43px",
        textTransform: "none",
        borderRadius: "8px",
        fontSize: "18px",
        fontWeight: "normal",
        backgroundColor: "#7E2AEA",
        color: "#ffffff",
        marginTop: "60px",
      }}
    >
      Загрузите инструкцию по установке
    </Button>
  </SectionStyled>
);
