import { Box, Container, CssBaseline, Grid, ThemeProvider, Typography, useMediaQuery } from "@mui/material";

import Header from "../components/ui/Header";
import Footer from "../components/ui/Footer";
import { CardItemVideo } from "components/lib/CardItemVideo";

import logoPenaHub from "../assets/Icons/black_logo_PenaHab.svg";

import { theme } from "../theme/theme";
import { cardsTour } from "__mocks__/cardsTour";

export default function Video() {
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header />
        <Box
          sx={{
            background: "#F2F3F7",
            paddingTop: "73px",
            paddingBottom: "160px",
          }}
        >
          <Container maxWidth="xl" fixed>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "80px",
                justifyContent: "flex-start",
                paddingBottom: "96px",
              }}
            >
              {isMobile ? (
                <></>
              ) : (
                <>
                  <img style={{ maxWidth: "180px", maxHeight: "70px" }} src={logoPenaHub} alt="PenaLtd" />
                </>
              )}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: "20px",
                }}
              >
                <Typography
                  variant="h6"
                  fontSize="calc(33px + 23 * (100vw / 1160))"
                  sx={{
                    lineHeight: "70px",
                    marginTop: "20px",
                    width: "min-content",
                  }}
                >
                  Видео руководство
                </Typography>
                <Typography
                  sx={{
                    fontSize: "24px",
                    maxWidth: "500px",
                    lineHeight: "28px",
                    alignSelf: "flex-end",
                  }}
                  variant="h6"
                >
                  Текст-заполнитель — это текст, который имеет некоторые характеристики реального письменного
                </Typography>
              </Box>
            </Box>
            <Grid container columns={{ xs: 2, lg: 6, xl: 12 }} columnGap="justify-content" rowGap="80px" spacing={2}>
              {cardsTour.map(({ title, text, image }, index) => (
                <Grid item xs={6}>
                  <CardItemVideo key={index} title={title} text={text} image={image} />
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
        <Footer />
      </ThemeProvider>
    </>
  );
}
