import { FC } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";

import SectionStyled from "./lib/SectionStyled";

import { BoxFich } from "./ui/BoxFich";

import amoLabel from "../assets/image/amoLabel.png";

export const ManagersSection: FC = () => (
  <SectionStyled
    tag={"section"}
    bg={"#333647"}
    mwidth={"1160px"}
    sxsect={{ minHeight: "1528px" }}
    sxcont={{
      display: "flex",
      justifyContent: "space-between",
      height: "100%",
      padding: "70px 10px",
      color: "#ffffff",
    }}
  >
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
      }}
    >
      <Box marginBottom={"76px"}>
        <Typography
          variant="h1"
          sx={{
            fontSize: {
              xxs: "24px",
              md: "36px",
            },
            fontWeight: "500",
            lineHeight: {
              xxs: "28px",
              md: "43px",
            },
          }}
        >
          Менеджеры будут продавать больше
        </Typography>
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: "400",
            lineHeight: "21px",
            marginTop: "20px",
          }}
        >
          за счет автоматизации документооборота
        </Typography>
      </Box>
      <BoxFich>
        <Box>
          <Typography
            sx={{
              color: "#7e2aea",
              fontSize: "24px",
              fontWeight: "500",
              lineHeight: "28.44px",
            }}
          >
            01
          </Typography>
          <Typography
            variant={"h6"}
            sx={{
              fontSize: "24px",
              fontWeight: "500",
              lineHeight: "28.44px",
              marginTop: "5px",
            }}
          >
            Создавайте любые документы
          </Typography>
        </Box>
        <Box>
          <Typography
            component="div"
            sx={{
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "21px",
              marginTop: "30px",
            }}
          >
            <ul>
              <li>договоры, акты, счета, накладные и др.</li>
              <li>в форматах docx или pdf</li>
              <li>загружайте свои шаблоны</li>
              <li>в любом количестве</li>
              <li>и скачивайте их в любое время</li>
            </ul>
          </Typography>
        </Box>
      </BoxFich>
      <BoxFich>
        <Box>
          <Typography
            sx={{
              color: "#7e2aea",
              fontSize: "24px",
              fontWeight: "500",
              lineHeight: "28.44px",
            }}
          >
            02
          </Typography>
          <Typography
            variant={"h6"}
            sx={{
              fontSize: "24px",
              fontWeight: "500",
              lineHeight: "28.44px",
              marginTop: "5px",
            }}
          >
            Удобно использовать
          </Typography>
        </Box>
        <Box>
          <Typography
            component="div"
            sx={{
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "21px",
              marginTop: "30px",
            }}
          >
            <ul>
              <li>предпросмотр документа</li>
              <li>
                выделение подставляемых в шаблон значений
                <br />в предпросмотре
              </li>
              <li>примечание о создании документа</li>
              <li>и многое другое</li>
            </ul>
          </Typography>
        </Box>
      </BoxFich>
      <BoxFich>
        <Box>
          <Typography
            sx={{
              color: "#7e2aea",
              fontSize: "24px",
              fontWeight: "500",
              lineHeight: "28.44px",
            }}
          >
            03
          </Typography>
          <Typography
            variant={"h6"}
            sx={{
              fontSize: "24px",
              fontWeight: "500",
              lineHeight: "28.44px",
              marginTop: "5px",
            }}
          >
            PenaDoc умеет
          </Typography>
        </Box>
        <Box>
          <Typography
            component="div"
            sx={{
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "21px",
              marginTop: "30px",
            }}
          >
            <ul>
              <li>склонять по падежам</li>
              <li>писать числа прописью</li>
              <li>писать дату прописью</li>
              <li>писать сумму прописью</li>
              <li>сокращать ФИО до инициалов</li>
              <li>несколько одновременных преобразований данных</li>
              <li>например: вычислить НДС и перевести его в строковый формат</li>
            </ul>
          </Typography>
        </Box>
      </BoxFich>
      <Box>
        <Button
          variant="contained"
          component="button"
          sx={{
            maxWidth: "350px",
            textTransform: "none",
            borderRadius: "8px",
            fontSize: "18px",
            fontWeight: "normal",
            backgroundColor: "#7E2AEA",
            color: "#ffffff",
            marginTop: "20px",
          }}
        >
          <img src={amoLabel} style={{ marginRight: "15px", filter: "invert(100%)" }} />
          Установить самостоятельно
        </Button>
      </Box>
    </Box>
  </SectionStyled>
);
