import { ReactNode } from "react";
import Box from "@mui/material/Box";
import { SxProps, Theme } from "@mui/system";

interface SectionProps {
  tag?: React.ElementType;
  bg?: string;
  sxsect?: SxProps<Theme>;
  mwidth?: string | number;
  sxcont?: SxProps<Theme>;
  children: ReactNode;
}

export default function SectionStyled({ tag = "section", bg, sxsect, mwidth, sxcont, children }: SectionProps) {
  return (
    <Box
      component={tag}
      backgroundColor={bg}
      sx={{
        width: "100%",
        fontFamily: "Rubik",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...sxsect,
      }}
    >
      <Box
        maxWidth={mwidth}
        sx={{
          width: "100%",
          padding: "0 5px",
          ...sxcont,
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
