import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import ButtonPlay from "../../assets/Icons/ButtonPlay.svg";
import { FC } from "react";

interface CardItemVideoProps {
  text: string;
  title: string;
  image: string;
}

export const CardItemVideo: FC<CardItemVideoProps> = ({ text, title, image }) => {
  return (
    <>
      <Box sx={{ maxWidth: "560px", display: "flex", flexDirection: "column", gap: "20px" }}>
        <Box sx={{ position: "relative" }}>
          <img style={{ width: "100%" }} src={image} alt="" />
          <Box
            sx={{
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              top: 0,
              left: 0,
              width: "100%",
              maxHeight: "340px",
              height: "100%",
            }}
          >
            <img src={ButtonPlay} alt="" />
          </Box>
        </Box>
        <Typography variant="h4" sx={{ fontSize: "20px", fontWeight: "500", lineHeight: "24px", marginTop: "10px" }}>
          {title}
        </Typography>
        <Typography variant="h5" sx={{ fontSize: "18px", fontWeight: "400", lineHeight: "21px" }}>
          {text}
        </Typography>
      </Box>
    </>
  );
};
