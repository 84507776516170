import { FC } from "react";
import { Square } from "./Squre";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

interface EffectCard {
  num: number;
  tit: string;
  subtit: string;
}

export const EffectCard: FC<EffectCard> = ({ num, tit, subtit }) => (
  <Box
    sx={{
      width: {
        xxs: "140px",
        md: "250px",
      },
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      marginTop: "15px",
    }}
  >
    <Square
      sx={{
        width: "59px",
        height: "59px",
        borderRadius: "6px",
        fontSize: "50px",
        fontWeight: "500",
        color: "#7E2AEA",
      }}
      num={num}
    />
    <Typography
      sx={{
        marginTop: "25px",
        fontSize: {
          xss: "18px",
          md: "24px",
        },
        fontWeight: "500",
        lineHeight: {
          xss: "21px",
          md: "26px",
        },
      }}
    >
      {tit}
    </Typography>
    <Typography
      sx={{
        fontSize: {
          xxs: "16px",
          md: "18px",
        },
        fontWeight: "400",
        marginTop: "8px",
      }}
    >
      {subtit}
    </Typography>
  </Box>
);
