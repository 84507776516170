import { TicketMessage } from "@frontend/kitui";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

export const useUnauthTicketStore = create()(
  persist(
    devtools(
      (set, get) => ({
        sessionData: null,
        isMessageSending: false,
        messages: [],
        apiPage: 0,
        messagesPerPage: 10,
        lastMessageId: undefined,
        isPreventAutoscroll: false,
      }),
      {
        name: "Unauth tickets",
      }
    ),
    {
      version: 0,
      name: "unauth-ticket",
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        sessionData: state.sessionData,
      }),
    }
  )
);

export const setUnauthSessionData = (sessionData) => useUnauthTicketStore.setState({ sessionData });
export const setIsMessageSending = (isMessageSending) => useUnauthTicketStore.setState({ isMessageSending });

export const addOrUpdateUnauthMessages = (receivedMessages) => {
  const state = useUnauthTicketStore.getState();
  const messageIdToMessageMap = {};

  [...state.messages, ...receivedMessages].forEach((message) => (messageIdToMessageMap[message.id] = message));

  const sortedMessages = Object.values(messageIdToMessageMap).sort(sortMessagesByTime);

  useUnauthTicketStore.setState({
    messages: sortedMessages,
    lastMessageId: sortedMessages.at(-1)?.id,
  });
};

export const incrementUnauthMessageApiPage = () => {
  const state = useUnauthTicketStore.getState();

  useUnauthTicketStore.setState({ apiPage: state.apiPage + 1 });
};

export const setUnauthIsPreventAutoscroll = (isPreventAutoscroll) =>
  useUnauthTicketStore.setState({ isPreventAutoscroll });

function sortMessagesByTime(ticket1, ticket2) {
  const date1 = new Date(ticket1.created_at).getTime();
  const date2 = new Date(ticket2.created_at).getTime();
  return date1 - date2;
}
