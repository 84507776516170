import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { Square } from "./Squre";

interface ProfitCardProps {
  pic: string;
  tit: string;
  subtit: string;
}

export const ProfitCard: FC<ProfitCardProps> = ({ pic, tit, subtit }) => (
  <Box
    sx={{
      width: {
        xxs: "100%",
        sm: "250px",
        lg: "300px",
      },
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      marginBottom: "40px",
    }}
  >
    <Square
      sx={{
        width: "59px",
        height: "59px",
        borderRadius: "6px",
        backgroundSize: "70%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundColor: "#ffffff",
      }}
      pic={pic}
    />
    <Typography
      paddingBottom={"0px"}
      sx={{
        fontSize: {
          xxs: "20px",
          md: "24px",
        },
        fontWeight: "500",
        lineHeight: "26px",
        marginTop: "30px",
      }}
    >
      {tit}
    </Typography>
    <Typography
      paddingBottom={"0px"}
      sx={{
        fontSize: "18px",
        fontWeight: "400",
        lineHeight: "26px",
        color: "#4D4D4D",
      }}
    >
      {subtit}
    </Typography>
  </Box>
);
