import { FC } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

import { Square } from "components/ui/Squre";

interface BenefitProps {
  pic: string;
  text: string;
}

export const Benefit: FC<BenefitProps> = ({ pic, text }) => (
  <Box
    sx={{
      minWidth: "250px",
      minHeight: "180px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      width: {
        xxs: "100%",
        md: "auto",
      },
      marginBottom: {
        xxs: "50px",
        md: "0",
      },
      borderBottom: {
        xxs: "2px solid white",
        md: "none",
      },
    }}
  >
    <Square
      sx={{
        width: "59px",
        height: "59px",
        marginBottom: "20px",
        /* backgroundColor: '#CBDADE', */
        borderRadius: "6px",
        backgroundImage: `url( ${pic} )`,
        backgroundSize: "90%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      pic={pic}
    />
    <Typography sx={{ width: "220px", fontSize: "24px", fontWeight: "500", lineHeight: "26px" }}>{text}</Typography>
  </Box>
);
