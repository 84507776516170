import React from "react";
import { Button } from "@mui/material";

const LoginButton = () => (
  <Button
    sx={{
      borderRadius: "8px",
      padding: "10px 18px",
      fontSize: "18px",
      lineHeight: "24px",
      fontWeight: 400,
      textTransform: "none",
      border: "1px solid black",
      color: "black",
    }}
    variant={"outlined"}
  >
    Личный кабинет
  </Button>
);

export default LoginButton;
