import { Box, PaletteColor, PaletteColorOptions, Typography, useMediaQuery, useTheme } from "@mui/material";
import { isDateToday } from "./date";

interface ChatMessageProps {
  unAuthenticated?: boolean;
  isSelf: boolean;
  text: string;
  createdAt: string;
}

declare module "@mui/material/styles" {
  interface Palette {
    grey2: PaletteColor;
    grey3: PaletteColor;
  }
  interface PaletteOptions {
    grey2?: PaletteColorOptions;
    grey3?: PaletteColorOptions;
  }
}

export default function ChatMessage({ unAuthenticated = false, isSelf, text, createdAt }: ChatMessageProps) {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"));

  const messageBackgroundColor = isSelf ? "white" : unAuthenticated ? "#EFF0F5" : theme.palette.grey2.main;

  const date = new Date(createdAt);
  const time = date.toLocaleString([], {
    hour: "2-digit",
    minute: "2-digit",
    ...(!isDateToday(date) && { year: "2-digit", month: "2-digit", day: "2-digit" }),
  });

  return (
    <Box
      sx={{
        display: "flex",
        alignSelf: isSelf ? "end" : "start",
        gap: "9px",
        pl: isSelf ? undefined : "8px",
        pr: isSelf ? "8px" : undefined,
      }}
    >
      <Typography
        sx={{
          alignSelf: "end",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "17px",
          order: isSelf ? 1 : 2,
          color: theme.palette.grey2.main,
          mb: "-4px",
          whiteSpace: "nowrap",
        }}
      >
        {time}
      </Typography>
      <Box
        sx={{
          backgroundColor: messageBackgroundColor,
          border: unAuthenticated ? `1px solid #E3E3E3` : `1px solid ${theme.palette.grey2.main}`,
          order: isSelf ? 2 : 1,
          p: upMd ? "18px" : "12px",
          borderRadius: "8px",
          maxWidth: "464px",
          color: isSelf || unAuthenticated ? theme.palette.grey3.main : "white",
          position: "relative",
        }}
      >
        <svg
          style={{
            position: "absolute",
            top: "-1px",
            right: isSelf ? "-8px" : undefined,
            left: isSelf ? undefined : "-8px",
            transform: isSelf ? undefined : "scale(-1, 1)",
          }}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="8"
          viewBox="0 0 16 8"
          fill="none"
        >
          <path
            d="M0.5 0.5L15.5 0.500007
                        C10 0.500006 7.5 8 7.5 7.5H7.5H0.5V0.5Z"
            fill={messageBackgroundColor}
            stroke={unAuthenticated ? "#E3E3E3" : theme.palette.grey2.main}
          />
          <rect y="1" width="8" height="8" fill={messageBackgroundColor} />
        </svg>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "19px",
          }}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
}
